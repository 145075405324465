import { ThemeProvider, themes } from "@doar/shared/styled";
import { GlobalStyle } from "@doar/shared/css";
import { useAppSelector } from "../hooks";

const Theme = ({ children }: { children: React.ReactNode }) => {
    const { theme } = useAppSelector((state) => state.theme);

    return (
        <ThemeProvider theme={themes[theme]}>
            <GlobalStyle />
            {children}
        </ThemeProvider>
    );
};

export default Theme;
